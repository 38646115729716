<template>
  <!-- 虚拟仿真实验详情 -->
  <div class="main-contain">
    <div class="bottom-contain">
      <div class="left-bts">
        <div
          :class="activebtid === index ? 'bt btactive' : 'bt'"
          v-for="(item, index) in btlist"
          :key="index"
          @click="clickbt(index, item)"
          v-clickDown="index"
        >
          <template v-if="activebtid === index">
            <el-image class="bt-icon" :src="item.icon2" />
          </template>
          <template v-else>
            <el-image class="bt-icon" :src="item.icon" />
          </template>
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div class="center-line"></div>
      <div class="right-contain">
        <!-- <component :is="componentId" ref="mycomponent"></component> -->
      </div>
    </div>
  </div>
</template>
<script>
// import Projectteam from "./components/projectteam.vue";

export default {
  name: "courselearning",
  components: {},
  directives: {
    clickDown: {
      inserted (el, binding, index) {
        if (binding.value === 0) {
          el.click()
        }
      }
    }
  },
  data () {
    return {
      tabbtid: 0,
      activebtid: 0,
      componentId: "Projectintroduction",
      returncomid: "",
      testimage: require("@/assets/test.png"),

      btlist: [
        {
          name: "安全公告",
          icon: require("@/assets/new/图层 61.png"),
          icon2: require("@/assets/new/图层 611.png"),
          component: "Projectintroduction",
        },
        {
          name: "规章制度",
          icon: require("@/assets/new/图层 62.png"),
          icon2: require("@/assets/new/图层 622.png"),
          component: "Projectintroduction",
        },
        {
          name: "学习资料",
          icon: require("@/assets/new/图层 63.png"),
          icon2: require("@/assets/new/图层 633.png"),
          component: "Projectintroduction",
        },
        {
          name: "安全手册",
          icon: require("@/assets/new/图层 64.png"),
          icon2: require("@/assets/new/图层 644.png"),
          component: "Projectintroduction",
        },
        {
          name: "安全标识",
          icon: require("@/assets/new/图层 65.png"),
          icon2: require("@/assets/new/图层 655.png"),
          component: "Projectintroduction",
        },
        {
          name: "安全视频",
          icon: require("@/assets/new/图层 66.png"),
          icon2: require("@/assets/new/图层 666.png"),
          component: "Projectintroduction",
        },

      ],
    }
  },
  created () { },

  mounted () { },
  methods: {
    tabbt (index) {
      this.tabbtid = index
    },

    clickbt (index, item) {
      // console.log('222')
      this.activebtid = index
      this.componentId = item.component
      this.$nextTick(() => {
        // this.$refs.mycomponent.initdata(this.vrinfo);
      })
    },
    toexperimental () {
      this.activebtid = 3
      this.componentId = "Experimentalresources"
    },
    toroomreservation () {
      // this.$router.push({
      //   path: "/openroom/roomreservation",
      // });
    },
  },
};
</script>
<style scoped lang="scss">
.main-contain {
  margin-bottom: 20px;

  .line {
    margin-top: 14px;
    // margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }

  .bottom-contain {
    // border: 1px solid red;
    height: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .left-bts {
      //   border: 1px solid red;
      margin-top: 60px;
      width: 260px;

      .bt {
        border-left: 2px solid transparent;
        padding-left: 50px;
        height: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        user-select: none;
        cursor: pointer;
        &:hover {
          border-left: 2px solid #3d84ff;
          background: linear-gradient(90deg, #92dbff 0%, #ffffff 100%);
        }
        .bt-icon {
          margin-right: 10px;
        }
        .elimage {
          margin-right: 10px;
        }
      }
      .btactive {
        border-left: 2px solid #3d84ff;
        background: linear-gradient(90deg, #92dbff 0%, #ffffff 100%);
      }
    }
    .center-line {
      margin-top: 20px;
      margin-left: 20px;
      height: 450px;
      border-right: 1px solid #cccccc;
    }
    .right-contain {
      // border: 1px solid red;
      margin-left: 20px;
      margin-top: 30px;

      height: 400px;
      flex: 1;
    }
  }
}
</style>
